body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*==========Generic styles==========*/
.bottom-border {
    border-bottom: 1px solid #C8CCCE;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-center-column {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.align-self-center {
    align-self: center;
}

.m-r-4 {
    margin-right: 4px !important;
}

.m-r-8 {
    margin-right: 8px !important;
}

.m-r-16 {
    margin-right: 16px !important;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-b-4 {
    margin-bottom: 4px !important;
}

.m-b-8 {
    margin-bottom: 8px !important;
}

.m-b-12 {
    margin-bottom: 12px !important;
}

.m-b-16 {
    margin-bottom: 16px !important;
}

.m-a-0 {
    margin: 0 !important;
}

.m-l-4 {
    margin-left: 4px !important;
}

.m-l-8 {
    margin-left: 8px !important;
}

.m-l-16 {
    margin-left: 16px !important;
}

.m-l-a {
    margin-left: auto !important;
}

.m-a-a {
    margin: auto !important;
}

.m-t-8 {
    margin-top: 8px !important;
}

.m-t-16 {
    margin-top: 16px !important;
}

.p-b-8 {
    padding-bottom: 8px !important;
}

.ellipsis-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
}

.break-words {
    word-break: break-word;
}
